import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useRoundtable from '../hooks/roundtable.hook';
import { IAppState } from '../store/store';
import CommonUtils from '../utils/common.utils';

export default function TableComponent({
    table,
    _join_room,
    occupySeat,
    countSeats,
    showUserDetails,
    getModeratedDetails
}: any): JSX.Element {
    

    const mockLoginUser = useSelector((state: IAppState) => JSON.parse(JSON.stringify(state.boothState.mockLoginUser)));
    const seatDetail = useSelector((state: IAppState) => JSON.parse(JSON.stringify(state.boothState.seatDetail)));
    const pubnub_subscribed = useSelector((state: IAppState) => state.boothState.pubnub_subscribed);
    const seatsCounter = useSelector((state: IAppState) => state.boothState.seatsCounter);
    const isLoading = useSelector((state: IAppState) => state.boothState.isLoading);
    const [isReservedUser, setIsReservedUser] = useState(false);

    const customizations = useSelector((state: IAppState) => state.boothState.customizations);
    // const unsubscribeTableId = useSelector((state: IAppState) => state.boothState.unsubscribeTableId);
    const [seat, setSeat] = useState<any>([]);
    const { id, title, description, logo, max_seats, json_config } = table;
    const config= JSON.parse(json_config);
    const uniqueIdentityPrefix = CommonUtils.getTableIdentityPrefix(max_seats);
    const [seatsCount, setSeatsCount] = useState(0);
    // CODE FROM HERE RESERVED SEATS ARRAY
    const [reservedUserSeats, setReservedUserSeats] = useState<any>([]);

    const colors = ['#ac1917', '#b75420', '#768b45', '#27958f', '#704776', '#1887ab', '#d34467', '#2b9300' ,'#007bff', '#6495ED','#ff8000','#ff00bf','#2b4700','#495057','#725348','#ef380e'];
    useEffect(() => {
        let seatss = [];
        let resev=[];



        for (let i = 0; i < max_seats; i++) {
            const unique_identity = `${uniqueIdentityPrefix}-${i + 1}`;
            let active = unique_identity === seatDetail.uuidSeat ? 'active' : '';
            let name: any = unique_identity === seatDetail.uuidSeat ? mockLoginUser.profilePic : '';
            let logo = unique_identity === seatDetail.uuidSeat ? mockLoginUser.profilePic : '';
            let nameInitials = '';
            let userDetail: any = unique_identity === seatDetail.uuidSeat ? mockLoginUser : '';
            let isReserved=false;
            let border_color='';
            let rsvd_id=""; 
            let rsvd_name="";
            let rsvd_image=""; 

            if(config!==null){
                if(config.reserved_seats === "on" && config.reserved_seat_users?.length > 0){
                    if(config.reserved_seat_users[i] !== undefined){
                        rsvd_id=config.reserved_seat_users[i].split("__")[0];
                        rsvd_name=config.reserved_seat_users[i].split("__")[1];
                        rsvd_image=config.reserved_seat_users[i].split("__")[2];
                        isReserved=true;
                        resev.push(rsvd_id+"_"+unique_identity);
                        setReservedUserSeats(resev);
                        if(rsvd_id === mockLoginUser.uuid){
                            setIsReservedUser(true);
                        }
                        
                    }
                }
            }
 
            if (pubnub_subscribed && pubnub_subscribed[id]) {
                pubnub_subscribed[id].forEach((e: any) => {
                    //console.log("Pubnub Saved Users",e.uuid);
                    if (unique_identity === e.seatId && e.reserveSeat !== -1) {
                        if (active === '') {
                            active = 'active';
                        }

                        name = e.profilePic;
                        nameInitials = CommonUtils.initials(e.name);
                        userDetail = e;

                    }
                    //console.log("rsvd_id:",rsvd_id,"pubnub_subscribed:",pubnub_subscribed[id]);
                    if(rsvd_id===e.uuid){
                        border_color='green';
                    }
                    
                    
                })
            }
            if(isReserved){
                if(border_color===""){
                    border_color='gray';
                }

                if (active === '') {
                    active = 'active reserved-user';
                }

                name = rsvd_image;
                nameInitials = CommonUtils.initials(rsvd_name);
                userDetail = {uuid:rsvd_id,name:rsvd_name};
                countSeats(id);
            }
            


            if (name) {
                name = (
                        <div key={`user-logo-${unique_identity}`} title={userDetail.name} style={{cursor: 'pointer'}} className="user" onClick={()=> showUserDetails(userDetail.uuid)}>
                            {border_color && (
                                <span  title={`${border_color ==='green' ? 'Online' : 'Offline'}`} className="reserved-user-status" style={{  background: `${border_color}` }} ></span>
                            )}
                            <img src={name} alt={` `} />
                        </div>
                    )
            } else if (nameInitials) {
                name = (
                        <div key={`user-logo-${unique_identity}`} title={userDetail.name} style={{ backgroundColor: colors[i], display: 'flex', justifyContent: 'center',cursor: 'pointer' }} className="user" onClick={()=> showUserDetails(userDetail.uuid)}>
                            <span className='user-initails' style={{ textTransform: 'uppercase', color: '#fff', fontWeight: 'bold', marginTop: '1.5px' }}>
                                {border_color && (
                                    <span  title={`${border_color ==='green' ? 'Online' : 'Offline'}`} className="reserved-user-status" style={{  background: `${border_color}` }} ></span>
                                )}
                                {nameInitials}
                            </span>
                        </div>
                    )
            }
        
            

            

            seatss.push(<div role={unique_identity} className={`chair chair${i + 1} ${active}`} key={unique_identity}
                onClick={() => {
                    if (!isLoading && !active) {
                        occupySeat(id, max_seats, unique_identity, reservedUserSeats);
                        setTimeout(() => {
                            countSeats(id);
                        }, 200);
                    }
                }}> 
                <img src='chair.png' />
                {name} </div>)
        }
        setSeat(seatss);
    }, [JSON.stringify(seatDetail), pubnub_subscribed, isLoading])

    useEffect(() => {
        setSeatsCount(seatsCounter[id]);
    }, [seatsCounter]);

    useEffect(() => {
        if (customizations?.tableBackground) {
            const head = document.getElementsByTagName('head')[0];
            const style = document.createElement('style');
            style.innerHTML = `
            .table:before {
                background: ${customizations?.tableBackground} !important;
            }
        `;
            head.appendChild(style);
        }
    }, [customizations])

    // useEffect(() => {
    //     if (unsubscribeTableId && unsubscribeTableId === id) {
    //         unsubscribeTable(unsubscribeTableId);
    //     }
    // }, [unsubscribeTableId, unsubscribeTable]);

    //console.log("reservetUserSeats",reservedUserSeats);

    return (

        <div id={'table_' + id} className="col-md-12 col-lg-6">
            <div className="card" style={{ background: customizations?.roundTableTileColor}}>
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <div className="content">
                            <h3>{title}</h3>
                            <p>{description}</p>
                            <a href="#"
                                style={{ background: customizations?.joinNowButtonBackground, color: customizations?.joinNowButtonForeColor }}
                                className={(seatsCount === 0 && !isReservedUser  ? 'disabled' : '') + ' primary-btn'} onClick={(e) => {
                                    e.preventDefault();
                                    if (!isLoading) {
                                        occupySeat(id, max_seats,"",reservedUserSeats);
                                        countSeats(id);
                                    }
                                }}>{seatsCount === 0 && !isReservedUser ? customizations?.tableAtCapacityButtonText.toUpperCase() : _join_room.toUpperCase()}</a>
                            <div className="availability">
                                <i className="fa fa-circle"></i> {`${(seatsCount == null || seatsCount === undefined || seatsCount < 0) ? max_seats : seatsCount} ${seatsCount === 1 ? customizations?.seatText : customizations?.seatText+"s"} Left`}
                                <p className='moderated-by-text' onClick={()=>reservedUserSeats.length!=0 ? getModeratedDetails(reservedUserSeats):""}>
                                    {reservedUserSeats.length!=0 && (
                                        "Moderated By "+reservedUserSeats.length+(reservedUserSeats.length === 1 ? " User" : " Users")
                                    )}
                                </p>

                                     
                            </div>
                            
                           
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className={`table for${max_seats}`}  >
                            <img src={logo} alt="vFairs" className="logo" style={{ background: customizations?.roundTableIconBgColor}} />
                            {seat}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}