import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IBoothState, ITablesResponse } from '../reducers/boothReducer';

export enum BoothActionTypes {
    MOCK_LOGGED_IN_USER = 'MOCK_LOGGED_IN_USER',
    RANDOM_BOOTH = 'RANDOM_BOOTH',
    LOAD_BOOTH = 'LOAD_BOOTH',
    ERROR = 'ERROR',
    INFO = 'INFO',
    SEAT_DETAIL = 'SEAT_DETAIL',
    PUBNUB_SUBSCRIBED = 'PUBNUB_SUBSCRIBED',
    CHOSEN_MEETING_DATA = 'CHOSEN_MEETING_DATA',
    SET_SEAT_COUNTER = 'SET_SEAT_COUNTER',
    IS_LOADING = 'IS_LOADING',
    RELOAD_REQUIRED = 'RELOAD_REQUIRED',
    UNSUBSCRIBE_TABLE = 'UNSUBSCRIBE_TABLE',
    CUSTOMIZATIONS = 'CUSTOMIZATIONS',
    USER_MODAL = "USER_MODAL",
    USER_MODAL_DETAILS = "USER_MODAL_DETAILS",
    MODERATED_MODAL = "MODERATED_MODAL",
    MODERATED_MODAL_DETAILS = "MODERATED_MODAL_DETAILS",    
}

export interface IRandomBoothAction {
    type: BoothActionTypes.RANDOM_BOOTH;
    channel: string[];
}

export interface ILoadBoothAction {
    type: BoothActionTypes.LOAD_BOOTH;
    loading: boolean;
}

export interface IErrorAction {
    type: BoothActionTypes.ERROR;
    errorMessage: string;
}

export interface ISeatDetailAction {
    uuid: string,
    uuidSeat: string,
    name: string,
}

export type BoothActions = IRandomBoothAction | ILoadBoothAction | IErrorAction;

/*<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const RandomBoothAction: ActionCreator<ThunkAction<Promise<any>, IBoothState, null, IRandomBoothAction>> = (dogBreed: string) => {
    return async (dispatch: Dispatch) => {
        try {
            let result = await (await fetch(`https://dog.ceo/api/breed/${dogBreed}/images/random`)).json();
            if (result.status !== 'success')
                throw new Error(result.message);
            dispatch({ image: result.message, type: BoothActionTypes.RANDOM_BOOTH });
        } catch (err) {
            console.error(err);
            dispatch({ type: BoothActionTypes.ERROR, errorMessage: err.message });
            dispatch({ type: BoothActionTypes.LOAD_BOOTH, loading: false });
        }
        ;
    };
};

export const loadBoothData: ActionCreator<ThunkAction<Promise<any>, IBoothState, null, IRandomBoothAction>> = (callback = null) => {
    return async (dispatch: Dispatch) => {
        try {
            // localStorage.setItem('user_info', '{"name":"Umar Ilyas","uuid":"628848336","email":"","logo":"","profilePic":""}')
            // localStorage.setItem('app_id', '494');
            let user_info = localStorage.getItem("user_info");
            const app_id = localStorage.getItem("app_id");

            if (!user_info) {
                throw new Error('User information not found in localstorage.');
            }

            if (user_info) {
                user_info = JSON.parse(user_info);
                dispatch({ mockLoggedInUser: user_info, type: BoothActionTypes.MOCK_LOGGED_IN_USER });
            }

            const result: ITablesResponse = await (await fetch(`https://uz7t3p61ik.execute-api.us-east-1.amazonaws.com/round-tables/${app_id}`)).json();
            if (result) {
                const { tables, settings } = result;
                dispatch({ channel: tables, type: BoothActionTypes.RANDOM_BOOTH });
                if (settings) {
                    dispatch({ payload: settings, type: BoothActionTypes.CUSTOMIZATIONS });
                }
            };
        } catch (err) {
            console.error("loadBoothData::ERROR::", err);
            dispatch({ type: BoothActionTypes.ERROR, errorMessage: err.message });
            dispatch({ type: BoothActionTypes.LOAD_BOOTH, loading: false });
        }
        ;
    };
};

export const storeChannelUser: ActionCreator<ThunkAction<Promise<any>, IBoothState, null, IRandomBoothAction>> = (table_number: number, user_id, callback = null) => {
    //event_id get it from localstorage or from store
    // event_id
    return async (dispatch: Dispatch) => {
        try {
            // let result = await (await fetch(`https://dog.ceo/api/breed/${dogBreed}/images/random`)).json();
            // if (result.status !== 'success')
            //     throw new Error(result.message);
            // dispatch({channel: result, type: BoothActionTypes.RANDOM_BOOTH});
            if (callback) {
                callback()
            }
        } catch (err) {
            console.error("loadBoothData::ERROR::", err);
            dispatch({ type: BoothActionTypes.ERROR, errorMessage: err.message });
            dispatch({ type: BoothActionTypes.LOAD_BOOTH, loading: false });
        }
    };
};

export const loadBoothAction: ActionCreator<ThunkAction<any, IBoothState, null, ILoadBoothAction>> = (shouldLoad: boolean) =>
    (dispatch: Dispatch) => dispatch({ type: BoothActionTypes.LOAD_BOOTH, loading: shouldLoad })

export const handleDispatchAction: any = (action: any, payload: any) =>
    (dispatch: Dispatch) => {
        switch (action) {
            case BoothActionTypes.RANDOM_BOOTH:
                dispatch({ channel: payload, type: BoothActionTypes.RANDOM_BOOTH });
                break;
            case BoothActionTypes.MOCK_LOGGED_IN_USER:
                dispatch({ mockLoggedInUser: payload, type: BoothActionTypes.MOCK_LOGGED_IN_USER });
                break;
            case BoothActionTypes.SEAT_DETAIL:
                dispatch({ seatDetail: payload, type: BoothActionTypes.SEAT_DETAIL });
                break;
            case BoothActionTypes.PUBNUB_SUBSCRIBED:
                dispatch({ pubnub_subscribed: payload, type: BoothActionTypes.PUBNUB_SUBSCRIBED });
                break;
            case BoothActionTypes.CHOSEN_MEETING_DATA:
                dispatch({ payload, type: BoothActionTypes.CHOSEN_MEETING_DATA });
                break;
            case BoothActionTypes.SET_SEAT_COUNTER:
                dispatch({ payload, type: BoothActionTypes.SET_SEAT_COUNTER });
                break;
            case BoothActionTypes.IS_LOADING:
                dispatch({ payload, type: BoothActionTypes.IS_LOADING });
                break;
            case BoothActionTypes.RELOAD_REQUIRED:
                dispatch({ payload, type: BoothActionTypes.RELOAD_REQUIRED });
                break;
            case BoothActionTypes.UNSUBSCRIBE_TABLE:
                dispatch({ payload, type: BoothActionTypes.UNSUBSCRIBE_TABLE });
                break;
            case BoothActionTypes.USER_MODAL:
                dispatch({ payload, type: BoothActionTypes.USER_MODAL });
                break;
            case BoothActionTypes.USER_MODAL_DETAILS:
                dispatch({  userModalDetails: payload,  type: BoothActionTypes.USER_MODAL_DETAILS});
                break;
            case BoothActionTypes.MODERATED_MODAL:
                dispatch({ payload, type: BoothActionTypes.MODERATED_MODAL });
                break;
            case BoothActionTypes.MODERATED_MODAL_DETAILS:
                dispatch({  moderatedModalDetails: payload,  type: BoothActionTypes.MODERATED_MODAL_DETAILS});
                break;    
                
                
        }
    }
