import { usePubNub } from "pubnub-react";
import { useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  BoothActionTypes,
  handleDispatchAction,
} from "../actions/boothActions";
import { NEW_SEAT_ALLOTMENT, OK } from "../constants/redis-message.constants";
import { MessageTypes } from "../enums/message.types.enum";
import { PresenceAction } from "../enums/presence.enum";
import { IAppState } from "../store/store";
import CommonUtils from "../utils/common.utils";
import { useHttpService } from "./http.hook";

declare var jQuery: any;

const customizations = {
  welcomeText: "Welcome to the Networking Lounge",
  takeASeatText: "Take a seat to join a conversation",
  headingTextColor: "#ffffff",
  subHeadingTextColor: "#ffffff",
  joinNowButtonText: "Join Now",
  joinNowButtonBackground: "Join Now",
  joinNowButtonForeColor: "",
  tableBackground: "#00293E",
  pageBackground: "#00293E", // could be url e.g. url('http://www.google.com/abc.png');
  tableAtCapacityButtonText: "Table At Capacity",
  seatText: "Seat",
  roundTableTileColor: "#214557",
};

export default function useRoundtable() {
  const RTAPI_URL =
    process.env.REACT_APP_API_URL != "" &&
    process.env.REACT_APP_API_URL != "undefined"
      ? process.env.REACT_APP_API_URL
      : "https://roundtable.vfairs.com/rtapi/";
  // const RTAPI_URL = 'http://localhost:4500/';
  // dummy comment
  const mockLoginUser = useSelector((state: IAppState) =>
    JSON.parse(JSON.stringify(state.boothState.mockLoginUser))
  );
  const channel = useSelector((state: IAppState) => state.boothState.channel);
  let store = useStore();
  const dispatch = useDispatch();
  const pubnub = usePubNub();
  const { post } = useHttpService();
  const [reserveSeat, setReserveSeat] = useState(-1);

  const [seatCount, setSeatCount] = useState(0);
  const eventId = localStorage.getItem("app_id");
  const eventChannelName = `roundtables_${eventId}`;
  const eventChannelGroup = `roundtables_${eventId}_group`;

  // const leaveSingleChannel = async (tableId: number) => {
  //     const channels = [String(tableId)];

  //     const response = await pubnub.getState({ channels, uuid: mockLoginUser.uuid })
  //     if (response && response.channels) {
  //         const userState = response.channels[String(tableId)];
  //         await pubnub.setState({ channels, state: { ...userState, online: false } });
  //     }

  //     pubnub.unsubscribe({ channels });
  // }

  // Method to leave all messages
  const leaveAllChannels = async (tableId?: number, clearRedisCache = true) => {
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, true));
    const channels = tableId ? [String(tableId)] : getTableIdsList();

    const data = {
      channels,
      eventId,
      uuid: mockLoginUser?.uuid,
    };

    if (clearRedisCache) {
      const addUserResponse = await post(`${RTAPI_URL}remove-user`, data).catch(
        (err) => err
      );
      if (addUserResponse?.message !== OK) {
        dispatch({
          type: BoothActionTypes.ERROR,
          errorMessage: addUserResponse?.message,
        });
        dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
        return;
      }
      if(tableId){
        sendTracking(tableId, 'left');
      }
    }

    // pubnub.getState({ channels, uuid: mockLoginUser.uuid })
    var chunk;
    while (channels.length > 0) {

        chunk = channels.splice(0,10)
        const response = await pubnub.setState({
            channels: chunk,
            state: { online: false },
          });
        console.log("******** LEAVE ALL CHANNELS STATE *************");
        console.log(response);
      
    }
    
    console.log(`Line: 78: UNSUBSCRIBING ${channels}`);
    pubnub.unsubscribe({ channels });
  };

  const delay = (timeout = 250) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, timeout);
    });
  };

  const checkHereNowSubscription = (tableId: number) => {
    return pubnub.hereNow({ channels: [String(tableId)] });
  };

  const getOccupiedSeatsCountOfTable = async (tableId: number) => {
    await delay(5000);
    const response = await pubnub.hereNow({ channels: [tableId.toString()] });
    if (response && response.channels) {
      const { occupancy } = response.channels[tableId];

      return occupancy;
    }

    return 0;
  };

  const countSeats = (tableId: number) => {
    const { id, max_seats } = channel.find((ch) => ch.id === tableId) as any;
    const chairsAvailable = jQuery(`#table_` + tableId)
      .find(`.for${max_seats}`)
      .find(".chair:not(.active)");
    const state = store.getState();
    const seatsCounter = { ...state.boothState.seatsCounter };
    seatsCounter[id] = Math.max(chairsAvailable.length, 0);
    dispatch(
      handleDispatchAction(BoothActionTypes.SET_SEAT_COUNTER, {
        ...seatsCounter,
      })
    );
  };

  const openMeetingPopup = async (user: any, max_seats: number, seatId: string) => {
    _occupySeat(user);
    await sendMessageToEventChannel({ ...user, type: MessageTypes.JoinSeat });

    // const { meeting_link } = channel.find(ch => ch.id === tableId) as any;
    // if (meeting_link) {
    //     dispatch(handleDispatchAction(BoothActionTypes.CHOSEN_MEETING_DATA, {
    //         meetingLink: meeting_link, tableId: tableId
    //     }));
    // }
  };

  const _occupySeat = (user: any) => {
    if (!user) {
      return;
    }

    const state = store.getState();
    const _pubnub_subscribed = { ...state.boothState.pubnub_subscribed };
    let users = _pubnub_subscribed[user.tableId];
    const { meeting_link } = channel.find(
      (ch) => ch.id === user.tableId
    ) as any;
    if (!users) {
      users = [];
    }

    delete user.type;
    delete user.online;
    users.push(user);
    dispatch(
      handleDispatchAction(BoothActionTypes.PUBNUB_SUBSCRIBED, {
        ..._pubnub_subscribed,
        [user.tableId]: users,
      })
    );
    if (meeting_link) {
      dispatch(
        handleDispatchAction(BoothActionTypes.CHOSEN_MEETING_DATA, {
          meetingLink: meeting_link,
          tableId: user.tableId,
        })
      );
    }

    return user;
  };

  const allocateRandomSeat = async (tableId: number,reserved_seat:any) => {
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, true));

    let user = {
      tableId,
      ...mockLoginUser,
    };

    const data = {
      eventId,
      user,
      maxSeats: channel.find((ch) => ch.id === tableId)?.max_seats,
      reserved_seat
    };
    const reserveSeatResponse = await post(
      `${RTAPI_URL}reserve-seat`,
      data
    ).catch((err) => err);
    if (reserveSeatResponse?.message !== OK) {
      dispatch({
        type: BoothActionTypes.ERROR,
        errorMessage: reserveSeatResponse?.message,
      });
      dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
      return;
    }

    user = reserveSeatResponse.data?.[0] || user;
    // await leaveAllChannels(tableId, false);
    _occupySeat(user);
    sendTracking(tableId, 'join');

    console.log(`Line: 166: SUBSCRIBING ${tableId}`);
    pubnub.subscribe({ channels: [String(tableId)] });
    countSeats(user.tableId);
    const stateResponse = await pubnub.setState({
      channels: [String(tableId)],
      state: { ...user, online: true },
    });
    console.log("************ STATE RESPONSE ************");
    console.log(stateResponse);
    await sendMessageToEventChannel({ ...user, type: MessageTypes.JoinSeat });
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
  };

  
  const occupySeat = async (tableId: number, maxSeats: number, seatId = "",reserved_seat:any) => {
  



    const state = store.getState();
    const alreadyChosenTableId = state.boothState.tableId;
    if (alreadyChosenTableId) {
      return;
    }

    if (!seatId) {
      
      const chairsAvailable = jQuery(`#table_` + tableId)
        .find(`.for${maxSeats}`)
        .find(".chair:not(.active)");
      const chairsForReservedUsers = jQuery(`#table_` + tableId)
        .find(`.for${maxSeats}`)
        .find(".chair:not(.active.reserved-user)");
      

      
      // console.log("occupySeat: ",tableId)
      // console.log("occupySeat: ",maxSeats)
      // console.log("occupySeat: ",seatId)
      // console.log("occupySeat: ",reserved_seat)  
      // console.log("chairsForReservedUsers: ",chairsForReservedUsers)  
      // console.log("reserved_seat: ",reserved_seat)  
      

      
      if( (chairsAvailable || chairsAvailable.length !== 0) && chairsForReservedUsers.length !== 0) {
        return await allocateRandomSeat(tableId, reserved_seat);
      }

    }

    const user = {
      tableId,
      seatId,
      ...mockLoginUser,
    };

    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, true));

    // try to add-user first
    const data = {
      eventId,
      user,
      maxSeats: channel.find((ch) => ch.id === tableId)?.max_seats,
      reserved_seat:reserved_seat
    };
    // console.log("occupySeat");
    // console.log(data);
    // const isAllowedResponse = await post(`${RTAPI_URL}is-allowed`, data).catch(err => err);
    // if (isAllowedResponse?.message !== OK) {
    //     dispatch({ type: BoothActionTypes.ERROR, errorMessage: isAllowedResponse?.message });
    //     dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
    //     return;
    // }

    // await leaveAllChannels(tableId);
    const addUserResponse = await post(`${RTAPI_URL}add-user`, data).catch(
      (err) => err
    );
    if (addUserResponse?.message !== OK) {
      dispatch({
        type: BoothActionTypes.ERROR,
        errorMessage: addUserResponse?.message,
      });
      dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
      if (!addUserResponse?.additionalMessage) {
        return;
      }
    }

    if (
      addUserResponse?.additionalMessage === NEW_SEAT_ALLOTMENT &&
      addUserResponse?.data?.[0]?.seatId
    ) {
      dispatch({
        type: BoothActionTypes.INFO,
        infoMessage: addUserResponse?.additionalMessage,
      });
      user.seatId = addUserResponse?.data?.[0]?.seatId;
    }
    user.seatId = addUserResponse?.data?.[0]?.seatId;

    console.log("addUserResponse",addUserResponse);
    _occupySeat(user);
    sendTracking(tableId, 'join');

    console.log(`Line: 232: SUBSCRIBING ${tableId}`);
    pubnub.subscribe({ channels: [String(tableId)] });
    countSeats(user.tableId);
    const stateResponse = await pubnub.setState({
      channels: [String(tableId)],
      state: { ...user, online: true },
    });
    console.log("************ STATE RESPONSE ************");
    console.log(stateResponse);
    await sendMessageToEventChannel({ ...user, type: MessageTypes.JoinSeat });
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
  };

  const _leaveSeat = (tableId?: number, userId = null) => {
    if (tableId) {
      const state = store.getState();

      const _pubnub_subscribed = { ...state.boothState.pubnub_subscribed };
      let users = _pubnub_subscribed[tableId];
      if (users) {
        userId = userId || mockLoginUser.uuid;
        const userIndex = users.findIndex((user: any) => user.uuid === userId);
        let user = null;
        if (userIndex > -1) {
          user = JSON.parse(JSON.stringify(users[userIndex]));
          users.splice(userIndex, 1);
        }

        dispatch(
          handleDispatchAction(BoothActionTypes.PUBNUB_SUBSCRIBED, {
            ..._pubnub_subscribed,
            [tableId]: users,
          })
        );
        dispatch(
          handleDispatchAction(BoothActionTypes.CHOSEN_MEETING_DATA, {
            meetingLink: null,
            tableId: null,
          })
        );

        return user;
      }
    }

    return null;
  };

  const leaveSeat = (tableId?: number) => {
    if (tableId) {
      leaveAllChannels(tableId).then((resp) => {
        setTimeout(() => {
          const userDetails = _leaveSeat(tableId);
          if (userDetails) {
            // pubnub.subscribe({ channels: [eventChannelName], withPresence: true });
            countSeats(tableId);
            sendMessageToEventChannel({
              ...userDetails,
              type: MessageTypes.LeaveSeat,
            }).then((resp) => {
              dispatch(
                handleDispatchAction(BoothActionTypes.IS_LOADING, false)
              );
            });
        }
        }, 2500);
      });
    }
  };

  const getTableIdsList = () => channel.map((table) => String(table.id));

  const sendMessageToEventChannel = async (user: any) => {
    console.log("************* PUBLISHING EVENT ***********");
    console.log(user);
    await pubnub.publish({
      channel: eventChannelName,
      message: user,
    });
  };

  const handleMessage = (messageEvent: any) => {
    if (messageEvent) {
      const { message } = messageEvent;
      // user is already added or left
      if (message.uuid === mockLoginUser.uuid) {
        return;
      }

      console.log("************* RECEIVING MESSAGE EVENT ***********");
      //console.log(message);

      const state = store.getState();
      const _pubnub_subscribed = { ...state.boothState.pubnub_subscribed };
      let users = _pubnub_subscribed[message.tableId];
      if (!users) {
        users = [];
      }

      const foundUserIndex = users.findIndex(
        (user: any) =>
          user.uuid === message.uuid && user.tableId === message.tableId
      );
      switch (message.type) {
        case MessageTypes.JoinSeat:
          if (foundUserIndex === -1) {
            // remove type and save in collection;
            delete message.type;
            delete message.online;
            users.push(message);
            dispatch(
              handleDispatchAction(BoothActionTypes.PUBNUB_SUBSCRIBED, {
                ..._pubnub_subscribed,
                [message.tableId]: users,
              })
            );
          }
          break;
        case MessageTypes.LeaveSeat:
          if (foundUserIndex > -1) {
            delete message.type;
            delete message.online;
            // remove type and save in collection;
            users.splice(foundUserIndex, 1);
            dispatch(
              handleDispatchAction(BoothActionTypes.PUBNUB_SUBSCRIBED, {
                ..._pubnub_subscribed,
                [message.tableId]: users,
              })
            );
          }
          break;
        default:
          break;
      }

      setTimeout(() => {
        countSeats(message.tableId);
      }, 200);
    }
  };

  const handlePresence = (event: any) => {
    const { action, uuid, channel } = event;
    if (channel === eventChannelName) {
      return;
    }

    const userDetails = JSON.parse(uuid);
    switch (action) {
      case PresenceAction.JOIN:
        // broadcast message for those users who have not subscribed to any channel yet.
        if (userDetails.uuid === mockLoginUser.uuid) {
          // sendMessageToEventChannel({ ...userDetails, type: MessageTypes.JoinSeat });
        }
        break;
      case PresenceAction.LEAVE:
        // broadcast message for those users who have not subscribed to any channel yet.
        if (userDetails.uuid === mockLoginUser.uuid) {
          // sendMessageToEventChannel({ ...userDetails, type: MessageTypes.LeaveSeat });
        }
        break;
      case PresenceAction.TIMEOUT:
        // if user is timeout and he is not the loggedIn user then remove him from the list
        if (userDetails.uuid !== mockLoginUser.uuid) {
          // _leaveSeat(userDetails.tableId, userDetails.uuid);
        }
        break;
      default:
        break;
    }
  };

  const handleStatus = (event: any) => {};

  const handleMessageAction = (event: any) => {};

  const initListeners = () => {
    pubnub.removeListener({ message: handleMessage });
    pubnub.addListener({
      message: handleMessage,
    });
  };

  const setUUID = async (user: any, subscribeToEventChannel = true) => {
    // pubnub.channelGroups.addChannels({ channels: [eventChannelName], channelGroup: eventChannelGroup })
    //  pubnub.setUUID(mockLoginUser.uuid);
    if (subscribeToEventChannel) {
      console.log(`Line: 386: SUBSCRIBING ${eventChannelName}`);
      pubnub.subscribe({ channels: [eventChannelName] });
    }
  };

  const setSeatCounter = (pubnubSubscriptions: any) => {
    const seatCounterHashMap: any = {};
    channel.forEach((ch) => {
      if (!seatCounterHashMap.hasOwnProperty(ch.id)) {
        seatCounterHashMap[ch.id] = ch.max_seats;
      }

      if (pubnubSubscriptions.hasOwnProperty(ch.id)) {
        seatCounterHashMap[ch.id] =
          ch.max_seats - pubnubSubscriptions[ch.id].length;
      }
    });

    dispatch(
      handleDispatchAction(BoothActionTypes.SET_SEAT_COUNTER, {
        ...seatCounterHashMap,
      })
    );
  };

  const syncState = async (removeMySelf = true) => {
    const tableIds = getTableIdsList();
    const result = await pubnub.hereNow({
      channels: [...tableIds],
      includeState: true,
    });
    const pubnubSubscriptions: any = {};
    if (result && result.channels && Object.keys(result.channels).length > 0) {
      for (let ch in result.channels) {
        const { name, occupancy, occupants } = result.channels[ch];
        if (occupancy > 0) {
          pubnubSubscriptions[Number(name)] = [];
          occupants.forEach((occupant) => {
            if (occupant.state) {
              const user = JSON.parse(JSON.stringify({ ...occupant.state }));
              if (user.tableId) {
                if (user.uuid === mockLoginUser.uuid && removeMySelf) {
                  sendMessageToEventChannel({
                    ...user,
                    type: MessageTypes.LeaveSeat,
                  }).then((resp) => {
                    console.log(`Line: 419: UNSUBSCRIBING ${user.tableId}`);
                    pubnub.unsubscribe({ channels: [user.tableId] });
                  });
                } else {
                  if (user.online) {
                    if (user.type) delete user.type;
                    if (user.hasOwnProperty("online")) delete user.online;
                    pubnubSubscriptions[Number(name)].push(user);
                  } else {
                    console.log(`Line: 431: UNSUBSCRIBING ${user.tableId}`);
                    pubnub.unsubscribe({ channels: [user.tableId] });
                  }
                }
              }
            }
          });
        }
      }
    }
    console.log("************* Syncing status ***********");
    console.log(pubnubSubscriptions);
    dispatch(
      handleDispatchAction(BoothActionTypes.PUBNUB_SUBSCRIBED, {
        ...pubnubSubscriptions,
      })
    );
    setSeatCounter(pubnubSubscriptions);
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
  };

  const startSyncingState = async () => {
    setTimeout(async () => {
      syncState(false).then((resp) => {
        startSyncingState();
      });
    }, 8000);
  };

  const initTables = async () => {
    console.log("*********************** WINDOW OBJECT *************");
    console.log(window);
    (window as any)["pubnub"] = pubnub;
    await leaveAllChannels();
    initListeners();
    setUUID(mockLoginUser);
    await syncState();
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
    autoJoin();
    // startSyncingState();
  };

  const showUserDetails = async (uid: number): Promise<void> => {
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, true));
    const app_id = localStorage.getItem("app_id");
    const RTAPI_USERDETAIL_URL =
      process.env.REACT_APP_API_URL_USERPROFILE != "" &&
      process.env.REACT_APP_API_URL_USERPROFILE != "undefined"
        ? process.env.REACT_APP_API_URL_USERPROFILE
        : "https://qa3-pro.vfairsdev.com/";

    const result = await (
      await fetch(
        `${RTAPI_USERDETAIL_URL}en/getUserDetailsForRoundtable?appId=${app_id}&user_id=${uid}`
      )
    ).text();
    if (result) {
      let re = /\s\s+/g;
      let resp = result.replace(re, " ");
      let reponse_object = JSON.parse(resp);
      if (reponse_object["error"] === undefined) {
        dispatch({
          userModalDetails: reponse_object,
          type: BoothActionTypes.USER_MODAL_DETAILS,
        });
        dispatch(handleDispatchAction(BoothActionTypes.USER_MODAL, true));
        const modal = document.getElementById("hiddenUserModal");
        modal?.click();
      } else {
        dispatch({
          type: BoothActionTypes.ERROR,
          errorMessage: reponse_object?.error,
        });
      }
      dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
    }
  };

  const getModeratedDetails = async (users: any): Promise<void> => {
    dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, true));
    const app_id = localStorage.getItem("app_id");
    const REACT_APP_API_BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL != "" && process.env.REACT_APP_API_BACKEND_URL != "undefined"
        ? process.env.REACT_APP_API_BACKEND_URL : "https://qa3-pro.vfairsdev.com/api/v3/";


    users=users.map((user:any) => user.split("_")[0]);
    // console.log("users",users);

    // const data = {
    //   users,
    //   app_id    
    // };
    const result = await (
      await fetch(
        `${REACT_APP_API_BACKEND_URL}roundtable/get-users-info?users=${users}&app_id=${app_id}`
      )
    ).text();
    // const result = await post(`${REACT_APP_API_BACKEND_URL}roundtable/get-users-info`, data)
    // .catch(
    //   (err) => 
    //   {
    //     dispatch({
    //       type: BoothActionTypes.ERROR,
    //       errorMessage: "API ERROR",
    //     });
    //     dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
    //     return;
    //   }
    // );
    //const result='{ "success": true, "message": [ { "id": "622112922", "first_name": "nomannnnnn", "last_name": "", "username": "nomankabeerr@vfairs.com", "user_image": "", "booth_id": "135030", "user_type_id": "3", "booth_name": "Booth C" }, { "id": "622113272", "first_name": "Atif", "last_name": "manzoor", "username": "matif@vfairs.com", "user_image": null, "booth_id": "135029", "user_type_id": "3", "booth_name": "Booth B" } ], "log": [] }';
    if (result) {

      let reponse_object = JSON.parse(result);
      dispatch({
        moderatedModalDetails: reponse_object.message,
        type: BoothActionTypes.MODERATED_MODAL_DETAILS,
      });
      dispatch(handleDispatchAction(BoothActionTypes.MODERATED_MODAL, true));

      setTimeout(() => {
        document.getElementById("hiddenModeratedModal")?.click();
        dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, false));
      }, 500);
      

    }
  };

  const autoJoin = async () => {
    const url = new URL(window.location.href);
    if (url.searchParams.has("auto_join")) {
      const auto_join = url.searchParams.get("auto_join") || 0;
      console.log(auto_join);
      if (auto_join !== "0") {
        console.log("Auto Join Table ID: " + auto_join);
        return await allocateRandomSeat(Number(auto_join),[]);
      }
    }
  };


  const sendTracking = async (table_id: number, action:string): Promise<void> => {
    //dispatch(handleDispatchAction(BoothActionTypes.IS_LOADING, true));
    const app_id = localStorage.getItem("app_id");
    const RTAPI_TRACKING_URL=  
      process.env.REACT_APP_API_URL_USERPROFILE != "" &&
      process.env.REACT_APP_API_URL_USERPROFILE != "undefined"
        ? process.env.REACT_APP_API_URL_USERPROFILE
        : "https://rtprofileapi.vfairs.com/en/AddTracking";  

    const UserId = mockLoginUser?.uuid;
    const ContentId = table_id;
    const TypeId = action==='join' ? 3000:3001;

    const result = await (
      await fetch(
        `${RTAPI_TRACKING_URL}en/AddTracking?UserId=${UserId}&TypeId=${TypeId}&ContentId=${ContentId}&AppId=${app_id}`
      )
    ).text();

    if(result){
      let re = /\s\s+/g;
      let resp = result.replace(re, " ");
      let reponse_object = JSON.parse(resp);
      if (reponse_object.Status !== true) {
        console.log("Tracking has some issues")
        return;
      }else{
        console.log("Tracking Added")
        return;
      }
    }
  };

  return {
    pubnub,
    channel,
    reserveSeat,
    seatCount,
    setSeatCount,
    getOccupiedSeatsCountOfTable,
    countSeats,
    occupySeat,
    leaveSeat,
    leaveAllChannels,
    initListeners,
    setUUID,
    initTables,
    loggedInUser: mockLoginUser,
    showUserDetails,
    getModeratedDetails,
    sendTracking
  };
}
