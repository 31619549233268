import React from 'react';

export default function ModalComponent({
    tableId, userDetail, handleClose, meetingLink, displayName, avatarUrl, tables }: any): JSX.Element {
   
    const currentTable = tables.find((obj:any) => obj.id === tableId);
    const { name, email, logo } = userDetail;
    const getAddOrStartQueryStringChar = (meetingLink: string) => meetingLink.indexOf('?') === -1 ? '?' : '&';
    const fixMeetingLink = (meetingLink: string) => {
        const addOrStartQueryStringChar = getAddOrStartQueryStringChar(meetingLink);
        // add avatarUrl to meetingLink
        let meeting_url = (
            meetingLink.indexOf('avatarUrl') === -1 && avatarUrl
                ? `${meetingLink}${addOrStartQueryStringChar}avatarUrl=${avatarUrl}`
                : meetingLink);
                //leaveButton=0
        let meeting_Link = (
            meeting_url.indexOf('leaveButton') === -1
                ? `${meeting_url}${addOrStartQueryStringChar}leaveButton=0`
                : meeting_url);   
                     
        // add displayName to meetingLink
        return (meeting_Link.indexOf('displayName') === -1 ?
            `${meeting_Link}${addOrStartQueryStringChar}displayName=${displayName}` :
            meeting_Link);
    };

    const turnScreenSharingOff = (meetingLink: string) => {
        const addOrStartQueryStringChar = getAddOrStartQueryStringChar(meetingLink);
        if (meetingLink.indexOf('screenshare') === -1) {
            const config = {
                screenshare: 0
            };

            return `${meetingLink}${addOrStartQueryStringChar}config=${btoa(JSON.stringify(config))}`;
        }

        return meetingLink;
    };

    if (!meetingLink) {

        if (!name && !email && !logo) {
            return <></>;
        }
    } else {
        meetingLink = fixMeetingLink(meetingLink);
        //meetingLink = turnScreenSharingOff(meetingLink);
    }

    if (meetingLink) {
        console.log('%cSTARTING MEETING', 'font-size: 20px; border: 5px dashed red; background: #000; color: #fff;');
        console.log(meetingLink);
    }

    let showMeetingIframe = meetingLink && (<div
        dangerouslySetInnerHTML={{
            __html: `<iframe width='100%' height='100%' src=${meetingLink} sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
    allow='cross-origin-isolated; speaker; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; camera; microphone; display-capture *;'
    style='width:100%;height:inherit;display:block;margin:0 auto;max-width:100%;min-height:100%;' allowfullscreen
 />`
        }}
    />);
    return (
        <>
            <button type="button" id='hiddenModal' className="btn btn-primary hiddenModal" style={{ display: 'none' }} data-toggle="modal" data-target="#userModal">
                Launch demo modal
            </button>
            <div className="modal fade myModal" id="userModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static"
                data-keyboard="false">
                <div className={`modal-dialog meetingModal`} role="document">
                    <div className="modal-content">
                        <div className="modal-header"><h4>{currentTable.title}</h4><button type="button" onClick={async () => meetingLink ? (handleClose(tableId)) : (handleClose())} style={{color:'red'}} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">Leave</span></button></div>
                        <div className="modal-body">
                            {
                                meetingLink ?
                                    showMeetingIframe :
                                    <div className="row">
                                        <div className="col-xs-12 col-md-3">
                                            <img src={logo} alt="N" className="img-fluid" />
                                        </div>
                                        <div className="col-xs-12 col-md-9">
                                            <div className="user-info">
                                                <h4>{name}</h4>
                                                <p><b>Email:</b> {email}</p>
                                            </div>
                                            <a href="#" className="primary-btn">See Full Profile</a>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}